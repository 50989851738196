import type { SupportedCountry } from '@archipro-website/localisation';

// eslint-disable-next-line no-restricted-syntax
enum PagesEnum {
    PROJECTS = '/projects',
    PRODUCTS = '/products',
    PROFESSIONALS = '/professionals',
    ARCHIZEEN = '/articles',
    DIGITAL_MAGAZINES = '/digital-magazines',
    BECOME_A_PRO = '/become-a-pro',
    FOR_SUPPLIERS_AND_BRANDS = '/sell-on-archipro',
    SELL_ON_ARCHIPRO = '/ecommerce-launch',
    ABOUT = '/about-us',
    CONTACT = '/contact-us',
    FAQS = `https://resources.{AP_DOMAIN_PLACE}/ecommerce-faqs`,
    HOME_DESIGN_EVENING = '/home-design-event',
    INSTAGRAM = 'https://www.instagram.com/archipro_/',
    FACEBOOK = 'https://www.facebook.com/archipro/',
    LINKEDIN = 'https://www.linkedin.com/company/archipro',
    YOUTUBE = 'https://www.youtube.com/channel/UCw3LUhWr0j-z2rpqGfQK8Tg',
    HOW_IT_WORKS = '/how-it-works',
    // Member-related routes
    MY_DESIGN_BOARDS = '/member/design-boards',
    MY_ORDERS = '/member/orders',
    MY_NOTIFICATIONS = '/member/notifications',
    MY_SETTINGS = '/member/settings/edit-profile',
    INBOX = '/member/inbox',
    LIST_BUSINESS = '/advertising',
}

export const FOOTER_DISCOVER_LINKS = {
    title: 'Discover',
    links: [
        {
            title: 'Projects',
            to: PagesEnum.PROJECTS,
        },
        {
            title: 'Products',
            to: PagesEnum.PRODUCTS,
        },
        {
            title: 'Professionals',
            to: PagesEnum.PROFESSIONALS,
        },
        {
            title: 'Archizeen',
            to: PagesEnum.ARCHIZEEN,
        },
        {
            title: 'Digital Magazines',
            to: PagesEnum.DIGITAL_MAGAZINES,
        },
    ],
};

export const FOOTER_BUSINESS_LINKS = {
    title: 'Business',
    links: [
        {
            title: 'Become a Pro',
            to: PagesEnum.BECOME_A_PRO,
        },
        {
            title: 'For Suppliers & Brands',
            to: PagesEnum.FOR_SUPPLIERS_AND_BRANDS,
        },
        {
            title: 'Sell on ArchiPro',
            to: PagesEnum.SELL_ON_ARCHIPRO,
        },
    ],
};

export const FOOTER_ARCHIPRO_LINKS = {
    title: 'ArchiPro',
    links: [
        {
            title: 'About',
            to: PagesEnum.ABOUT,
        },

        {
            title: 'Contact ArchiPro',
            to: PagesEnum.CONTACT,
            isNonRemixRoute: true,
        },
        {
            title: 'FAQs',
            to: PagesEnum.FAQS,
        },
    ],
};

export const FOOTER_CONNECT_LINKS = {
    title: 'Connect',
    links: [
        {
            title: 'Home Design Evening',
            to: PagesEnum.HOME_DESIGN_EVENING,
            isNonRemixRoute: true,
        },
        {
            title: 'Instagram',
            to: PagesEnum.INSTAGRAM,
        },
        {
            title: 'Facebook',
            to: PagesEnum.FACEBOOK,
        },
        {
            title: 'Linkedin',
            to: PagesEnum.LINKEDIN,
        },
        {
            title: 'YouTube',
            to: PagesEnum.YOUTUBE,
        },
    ],
};

export const MOBILE_HEADER_PRIMARY_NAV = {
    title: 'MOBILE_HEADER_PRIMARY_NAV',
    links: [
        { title: 'Source Products', to: PagesEnum.PRODUCTS },
        { title: 'Explore Projects', to: PagesEnum.PROJECTS },
        { title: 'Hire Professionals', to: PagesEnum.PROFESSIONALS },
        { title: 'Read Articles', to: PagesEnum.ARCHIZEEN },
    ],
};

export const MOBILE_HEADER_SECONDARY_NAV = {
    title: 'MOBILE_HEADER_SECONDARY_NAV',
    links: [
        { title: 'Digital Magazines', to: PagesEnum.DIGITAL_MAGAZINES },
        { title: 'About us', to: PagesEnum.ABOUT },
        { title: 'FAQ', to: PagesEnum.FAQS },
        {
            title: 'Home Design Evening',
            to: PagesEnum.HOME_DESIGN_EVENING,
            isNonRemixRoute: true,
        },
    ],
};

export const PROFILE_NAV = {
    title: 'PROFILE_NAV',
    links: [
        { title: 'Inbox', to: PagesEnum.INBOX, isNonRemixRoute: true },
        { title: 'Design Boards', to: PagesEnum.MY_DESIGN_BOARDS },
        { title: 'Orders', to: PagesEnum.MY_ORDERS, isNonRemixRoute: true },
        {
            title: 'Notifications',
            to: PagesEnum.MY_NOTIFICATIONS,
            isNonRemixRoute: true,
        },
        {
            title: 'My Account',
            to: PagesEnum.MY_SETTINGS,
            isNonRemixRoute: true,
        },
        {
            title: 'List a Business',
            to: PagesEnum.LIST_BUSINESS,
            isNonRemixRoute: true,
        },
    ],
};

export const DESKTOP_NAV = {
    title: 'DESKTOP_NAV',
    links: [
        { prefix: 'Find', to: PagesEnum.PRODUCTS, title: 'Products' },
        {
            prefix: 'Hire',
            to: PagesEnum.PROFESSIONALS,
            title: 'Professionals',
        },
        { prefix: 'Explore', to: PagesEnum.PROJECTS, title: 'Projects' },
        {
            prefix: 'Home',
            to: PagesEnum.HOME_DESIGN_EVENING,
            isNonRemixRoute: true,
            title: 'Design Evening',
        },
    ],
};

export const getPrivacyPolicyLink = (countryCode: SupportedCountry): string => {
    return `https://email.${getDomain(countryCode)}/archipro-privacy-and-data-policy-${getPostfix(countryCode)}`;
};

export const getWebsiteTermsLink = (countryCode: SupportedCountry): string => {
    return `https://email.${getDomain(countryCode)}/archipro-website-terms-of-use-${getPostfix(countryCode)}`;
};

// these are not exported from urlHelper.ts because urlHelper has shit lots of dependencies
// which we don't need here (and "manager" build is yelling on them)
// ideally we need to refactor urlHelper (maybe move it to rocco url module?)
const getDomain = (countryCode: SupportedCountry): string => {
    switch (countryCode) {
        case 'AU':
            return 'archipro.com.au';
        default:
            return 'archipro.co.nz';
    }
};

const getPostfix = (countryCode: SupportedCountry): string => {
    switch (countryCode) {
        case 'AU':
            return 'aus';
        default:
            return 'nz';
    }
};
