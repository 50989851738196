import {
    Outlet,
    isRouteErrorResponse,
    useLocation,
    useMatches,
    useNavigation,
    useRouteError,
    useFetcher,
} from '@remix-run/react';
import type { SerializeFrom } from '@remix-run/node';
import { useLatest } from 'ahooks';
import { CatchPage, ServerErrorPage } from '@modules/error';
import FooterNavigation from '@modules/navigation/component/footer-navigation/FooterNavigation';
import {
    makePageViewTrackingData,
    useLogPageView,
    useTracker,
    useLogLinkClicks,
} from '@archipro-website/tracker';
import {
    CartPopupProvider,
    useCartPopupState,
} from '@modules/ecommerce/context/CartPopupContext';
import { useHideNavigation } from '@modules/root/hook/use-hide-navigation';
import { useWarningMessages } from '@modules/root/hook/use-warning-messages';
import type { TopNavigationData } from '@modules/navigation/component/top-navigation/TopNavigation';
import TopNavigation from '@modules/navigation/component/top-navigation/TopNavigation';
import {
    useAppData,
    useMatchesHandleData,
    useMaybeAppData,
    useRootData,
} from '@modules/root';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import { useOneTapSignIn } from '~/modules/user/hook/use-one-tap-sign-in';
import React from 'react';
import DesignBoardInitializer from '~/modules/root/component/design-board/DesignBoardInitializer';
import CountrySuggestModal from '@modules/root/component/country-suggest-modal/CountrySuggestModal';

import SiteWideModals from '@modules/root/component/site-wide-modals/SiteWideModals';
import { ToasterProvider, useToaster } from '../context/ToasterContext';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import { SaveToDesignBoardProvider } from '~/modules/design-board/component/context/SaveToDesignBoardContext';
import { useOnObjectChange } from '@archipro-website/react-hooks';
import { useDeploymentShouldReload } from '@archipro-website/react-hooks';
import { getServerVersion } from '../util';
import { useABTestIsOn } from '../hook/use-growthbook';
import { Flex, ImageConfigProvider, useStyles } from '@archipro-design/aria';
import * as S from '../component/app-page-layout/AppPageLayout.style';
import { imageConfig } from '~/modules/images';
import { useAuthEvent } from '../hook/use-auth-event';
import { AuthContextProvider } from '~/modules/root/context';
import type { loader } from '~/routes/_app';
import type { GrowthBookSSRData } from '@growthbook/growthbook-react';
import { useGrowthBookSSR } from '@growthbook/growthbook-react';
import BookingModal from '@modules/enquiry/component/booking-modal/BookingModal';

import type { HeaderCartInfo } from '@rocco/ui/website-header/components/Header';
import { Header } from '@rocco/ui/website-header/components/Header';
import { Footer } from '@rocco/ui/website-footer/components/Footer';

import {
    DESKTOP_NAV,
    FOOTER_ARCHIPRO_LINKS,
    FOOTER_BUSINESS_LINKS,
    FOOTER_CONNECT_LINKS,
    FOOTER_DISCOVER_LINKS,
    getPrivacyPolicyLink,
    getWebsiteTermsLink,
    MOBILE_HEADER_PRIMARY_NAV,
    MOBILE_HEADER_SECONDARY_NAV,
} from '~/utils/route-config';
import { getDomain, placeCountryDomain } from '~/utils/urlHelper';
import {
    countriesList,
    type SupportedCountry,
} from '@archipro-website/localisation';
import type { action } from '~/routes/remix-api.user.subscription';
import { getUserHeaderData } from '../util/get-user-header-data';
import { useLogout } from '~/modules/user/hook/use-logout';
import {
    countriesMenuItem,
    pathsThatShouldRedirectToHome,
    swapTLDDomain,
} from '@archipro-website/top-navigation/src/lib/slots/flagSlot';
import { usePageTheme } from '~/modules-rocco/pages/hooks/use-page-theme';
// import { useAnimatedFavicon } from '@rocco/ui/favicon/hook/use-animated-favicon';
import { Toaster as RoccoToaster } from '@rocco/components/sonner';
import { DesignBoardsActions as RoccoDesignBoardsActions } from '@rocco/ui/design-board/components/DesignBoardsActions';
import { useDesignBoards } from '@ui-rocco/hooks/use-design-boards';
import { MobileSearchModal } from '~/modules-rocco/search/page/mobile-search-modal-flow';
import { ClientOnly } from 'remix-utils';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSetLoginModal } from '~/modules-rocco/user/hook/use-open-login-modal';
import { useEnquiryAutoSubmit } from '~/modules-rocco/enquiry/hooks/use-enquiry-auto-submit';
import { uiStateAtomAddProfessionalModalOpened } from '~/modules-rocco/root/states/ui-state-add-professional-modal-opened';
import { useFooterTracking } from '@ui-rocco/hooks/use-footer-tracking';
import type { SubscriptionFetcher } from '@rocco/ui/website-footer/components/EmailSubscriptionForm';
import { EmailSubscriptionForm } from '@rocco/ui/website-footer/components/EmailSubscriptionForm';
import { uiStateAtomSearchModalOpened } from '~/modules-rocco/search/states/ui-state-search-modal-opened';
import { pinsAtomWithLocalStorage } from '~/modules-rocco/design-board/hook/use-handle-follow';
import type { Pin } from 'generated/graphql';
import { cn } from '@rocco/utils/cn';
import type { HeaderCountryElement } from '~/utils/country-switch';
import { getCountrySwitchUrl } from '~/utils/country-switch';
import { getCloneCountryStateAtom } from '@archipro-website/web/app/state/ui-state-clone-country-link';

const AppProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <ToasterProvider>
            <CartPopupProvider>{children}</CartPopupProvider>
        </ToasterProvider>
    );
};

export type AppData = SerializeFrom<typeof loader>;

export const links = () => [
    {
        rel: 'stylesheet',
        href: '/assets/website/styles/swiper@9.1.0/swiper-bundle.min.css',
    },
];

/**
 * renders top nav and footer around its children
 * It requires app loader to return data.
 * @param children
 * @param data
 * @constructor
 */
const AppLayout = ({
    children,
    data,
}: {
    children: JSX.Element;
    data: AppData;
}) => {
    const config = useConfigContext();
    const location = useLocation();
    const matches = useMatches();
    const cartPopupState = useCartPopupState();
    const warningMessages = useWarningMessages();

    // useAnimatedFavicon();

    /**
     * we use useMatches here because we cannot call useLoaderData inside
     * Error boundaries. Since we are can't guarantee that we do have app data
     * we use the optional version
     */
    const {
        shoppingCartSummary,
        notifications,
        user,
        remixEnabled,
        ecommerceEnabled,
        backToTopEnabled,
        shopNavEnabled,
        searchPreFiliteringEnabled,
        footerCounterEnabled,
        toastMessage,
        imageConfigProps,
        monthlyUserCount,
        professionalBookingLink,
        roccoBaseEnabled,
        canCreateProfessional,
        canApproveContent,
        designBoardItems,
    } = data;

    const guestHomeEnabled = useABTestIsOn('guest-homepage');
    const isBMPreview = useMatchesHandleData('isBMPreview', false);
    const hideContactArchiPro = useMatchesHandleData<boolean>(
        'hideContactArchiPro',
        false
    );

    const topNavigationData = {
        shoppingCart: shoppingCartSummary?.ShoppingCart,
        notifications,
        user,
    };

    const {
        env,
        config: { webpush },
        growthbookContext,
        user: trackedUser,
    } = useRootData();

    const tracker = useTracker();
    const { onLogoutClick } = useLogout();

    useGrowthBookSSR(growthbookContext as GrowthBookSSRData);

    useOneTapSignIn(config.google.otsId, isBMPreview);

    // Fires page view events
    useLogPageView(location, tracker, (state) =>
        makePageViewTrackingData(state)
    );

    // Fire website referral & download tracking events
    useLogLinkClicks(location, tracker, trackedUser);

    useEnquiryAutoSubmit();
    // TODO: Remove this when the old enquiry form is retired.
    useEnquirySession(
        {
            canHandleAutoSubmit: true,
        },
        undefined,
        tracker
    );

    useAuthEvent(tracker);

    useOnObjectChange(
        {
            hashKey: 'feature-flags',
            value: {
                user: {
                    id: user.ID,
                    type: user.__typename,
                },
                features: user.FeatureFlags.map(({ Name, Enabled }) => {
                    return { Name, Enabled };
                }),
            },
            callback: () => {
                window.location.reload();
            },
        },
        [user]
    );

    const { topNavigation, footerNavigation } = useHideNavigation(matches);
    const isLoggedIn = user.__typename === 'Me';
    const isHomePage = matches.every((match) => match.pathname === '/');
    const showGuestNav = guestHomeEnabled && isHomePage && !isLoggedIn;

    const { toast, enquiryToastClickHere } = useToaster();

    React.useEffect(() => {
        if (!toastMessage) return;
        toast(toastMessage.message, {
            type: toastMessage.type,
            ...(toastMessage.enquiryClickLink && {
                clickHereLink: enquiryToastClickHere, // For enquiry toasts
            }),
        });
    }, [toastMessage, toast, enquiryToastClickHere]);

    const shouldReload = useDeploymentShouldReload({
        idleTimeout: 60e3,
        clientVersion: env.version,
        host: env.host,
        getServerVersion,
        config: webpush,
    });
    const navigation = useNavigation();
    const shouldReloadRef = useLatest(shouldReload);

    const countryData = useAtomValue(getCloneCountryStateAtom);

    React.useEffect(() => {
        if (navigation.state === 'loading' && shouldReloadRef.current()) {
            window.location.replace(
                `${navigation.location.pathname}${navigation.location.search}`
            );
        }
    }, [navigation, shouldReloadRef]);

    const { appDisplayMode, localisation } = useRootData();

    const currentCountryCode = localisation.country; // FIXME
    const domain = getDomain(currentCountryCode);

    const pathParts = location.pathname.split('/').filter(Boolean) ?? [];
    const shouldRedirectHome = pathsThatShouldRedirectToHome.includes(
        pathParts[0] ?? ''
    );

    const pathAndSearch = shouldRedirectHome
        ? ''
        : (location.pathname ?? '') + (location.search ?? '');
    const countries = Object.values(countriesList).map((country) => {
        const countryElement: HeaderCountryElement = {
            code: country.shortName,
            name: country.name,
            url:
                swapTLDDomain(
                    env,
                    currentCountryCode,
                    country.shortName as SupportedCountry,
                    countriesMenuItem.find((c) => c.code === country.shortName)
                        ?.link
                ) + pathAndSearch,
        };
        return {
            ...countryElement,
            url: getCountrySwitchUrl(
                countryElement,
                countryData,
                location.pathname
            ),
        };
    });

    const { headerUserData, profileLinks } = getUserHeaderData(
        user,
        notifications
    );
    const subscriptionFetcher = useFetcher<typeof action>(); // FIXME
    const currentUrl = location.pathname + location.search;

    const pageTheme = usePageTheme();
    const [currentPageTheme, setCurrentPageTheme] = React.useState(pageTheme);
    React.useEffect(() => {
        setCurrentPageTheme(pageTheme);
        if (appDisplayMode !== 'mobile' || pageTheme === 'none') return;
        const toggleTheme = () => {
            window.scrollY > 100
                ? setCurrentPageTheme('default')
                : setCurrentPageTheme(pageTheme);
        };
        toggleTheme();
        window.addEventListener('scroll', toggleTheme);
        return () => {
            window.removeEventListener('scroll', toggleTheme);
        };
    }, [currentUrl, pageTheme, appDisplayMode]);

    const { css } = useStyles({ currentPageTheme });
    const [, setSearchModalOpened] = useAtom(uiStateAtomSearchModalOpened);

    // design board UI api
    const designBoards = useDesignBoards(); // TODO: implement interface for it
    const footerTrackingConfig = useFooterTracking();

    const { setLoginModal } = useSetLoginModal();
    const setAddProfessionalModalOpened = useSetAtom(
        uiStateAtomAddProfessionalModalOpened
    );

    const setPins = useSetAtom(pinsAtomWithLocalStorage);

    React.useEffect(() => {
        if (!designBoardItems) return;
        const { DesignBoardListItems } = designBoardItems;
        const record = DesignBoardListItems.nodes
            ?.map((node) => [...(node.Pins || [])])
            .flat();

        setPins(record as Pin[]);
    }, [designBoardItems, setPins]);

    const additionalUserMenuItems =
        canCreateProfessional || canApproveContent
            ? {
                  links: [
                      ...(canCreateProfessional
                          ? [
                                {
                                    title: 'Add Professional',
                                    to: '#',
                                    onClick: () => {
                                        setAddProfessionalModalOpened(true);
                                    },
                                },
                            ]
                          : []),
                      ...(canApproveContent
                          ? [
                                {
                                    title: 'Content Hub',
                                    to: '/ap-admin/content/projects',
                                    external: true,
                                },
                            ]
                          : []),
                  ],
              }
            : undefined;

    const onMobileSearchClick = () => {
        setSearchModalOpened(true);
    };

    const { shoppingCart } = topNavigationData;
    const cartInfo: HeaderCartInfo = {
        items:
            shoppingCart?.LastAddedItems?.map((el) => ({
                id: el.ID,
                title: el.Variant?.Product?.Title,
                price: el.Price.ShortFormat,
                quantity: el.Quantity,
                isSample: el.Sample,
                thumbnail: {
                    desktop: el.Variant?.Thumbnail?.Desktop ?? '',
                    mobile: el.Variant?.Thumbnail?.Mobile ?? '',
                },
            })) || [],
        itemTotal: {
            ShortFormat: shoppingCart?.ItemTotal?.ShortFormat || '',
            Amount: shoppingCart?.ItemTotal?.Amount || 0,
        },
        cartCount: notifications?.cart?.count ?? 0,
    };

    return (
        <ImageConfigProvider value={imageConfig(imageConfigProps)}>
            <AuthContextProvider>
                <SaveToDesignBoardProvider>
                    <Flex column className={cn(css(S.PageBase))}>
                        {topNavigation ? (
                            roccoBaseEnabled ? (
                                <Header
                                    cartInfo={cartInfo}
                                    onUserCartClick={() => {
                                        window.location.href = '/shopping-cart';
                                    }}
                                    currentUrl={currentUrl}
                                    userData={headerUserData}
                                    currentCountryCode={currentCountryCode}
                                    countries={countries}
                                    primaryDesktopLinks={DESKTOP_NAV}
                                    profileMobileLinks={profileLinks}
                                    primaryMobileLinks={placeCountryDomain(
                                        MOBILE_HEADER_PRIMARY_NAV,
                                        domain
                                    )}
                                    secondaryMobileLinks={placeCountryDomain(
                                        MOBILE_HEADER_SECONDARY_NAV,
                                        domain
                                    )}
                                    onLogout={onLogoutClick}
                                    displayMode={appDisplayMode}
                                    color={currentPageTheme}
                                    mode={'fixed'}
                                    additionalUserMenuItems={
                                        additionalUserMenuItems
                                    }
                                    warningMessages={warningMessages}
                                    onLoginClick={() => {
                                        setLoginModal({
                                            authSource: 'topNav',
                                            isOpen: true,
                                        });
                                    }}
                                    onMobileSearchClick={onMobileSearchClick}
                                />
                            ) : (
                                <TopNavigation
                                    //#techdebt: this is a hack to appease the type check gods
                                    data={
                                        topNavigationData as TopNavigationData
                                    }
                                    cartPopupState={cartPopupState}
                                    remixEnabled={remixEnabled}
                                    ecommerceEnabled={ecommerceEnabled}
                                    backToTopEnabled={backToTopEnabled}
                                    shopNavEnabled={shopNavEnabled}
                                    searchPreFiliteringEnabled={
                                        searchPreFiliteringEnabled
                                    }
                                    showGuestNav={showGuestNav}
                                    warningMessages={warningMessages}
                                />
                            )
                        ) : null}
                        <div className={css(S.PageContent)}>{children}</div>
                        {footerNavigation ? (
                            roccoBaseEnabled ? (
                                <Footer
                                    linksColumn1={[
                                        {
                                            ...placeCountryDomain(
                                                FOOTER_DISCOVER_LINKS,
                                                domain
                                            ),
                                        },
                                        {
                                            ...placeCountryDomain(
                                                FOOTER_BUSINESS_LINKS,
                                                domain
                                            ),
                                        },
                                    ]}
                                    linksColumn2={[
                                        {
                                            ...placeCountryDomain(
                                                FOOTER_ARCHIPRO_LINKS,
                                                domain
                                            ),
                                        },
                                        {
                                            ...placeCountryDomain(
                                                FOOTER_CONNECT_LINKS,
                                                domain
                                            ),
                                        },
                                    ]}
                                    websiteTermLink={getWebsiteTermsLink(
                                        currentCountryCode
                                    )}
                                    privacyPolicyLink={getPrivacyPolicyLink(
                                        currentCountryCode
                                    )}
                                    displayMode={appDisplayMode}
                                    trackingConfig={footerTrackingConfig}
                                    subscriptionForm={
                                        <EmailSubscriptionForm
                                            action="/remix-api/user/subscription"
                                            fetcher={
                                                subscriptionFetcher as SubscriptionFetcher
                                            }
                                        />
                                    }
                                    hideContactArchiPro={hideContactArchiPro}
                                />
                            ) : (
                                <FooterNavigation
                                    remixEnabled={remixEnabled}
                                    showGuestNav={showGuestNav}
                                    displayUserCounter={footerCounterEnabled}
                                    monthlyUserCount={monthlyUserCount}
                                />
                            )
                        ) : null}
                    </Flex>
                    <DesignBoardInitializer />
                    <CountrySuggestModal />
                    <SiteWideModals />
                    <RoccoToaster />
                    <ClientOnly>
                        {() => (
                            <RoccoDesignBoardsActions
                                isUserLoggedIn={isLoggedIn}
                                displayMode={appDisplayMode}
                                trackingConfig={designBoards.trackingConfig}
                                isLoading={designBoards.isLoading}
                                boards={designBoards.boards}
                                addedItemsCount={
                                    designBoards.addedItemsData?.addedCount
                                }
                                createdBoardData={designBoards.createdBoardData}
                                onFetchBoards={designBoards.handleFetchBoards}
                                onCreateBoard={designBoards.handleCreateBoard}
                                onAddItemsToBoard={
                                    designBoards.handleAddItemsToBoard
                                }
                            />
                        )}
                    </ClientOnly>
                    {professionalBookingLink && (
                        <BookingModal
                            teamBookingSegment={professionalBookingLink}
                            initialOpen={true}
                        />
                    )}
                    {appDisplayMode === 'mobile' && <MobileSearchModal />}
                </SaveToDesignBoardProvider>
            </AuthContextProvider>
        </ImageConfigProvider>
    );
};

export const ErrorBoundary = () => {
    const data = useMaybeAppData();
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (data) {
            return (
                <AppProviders>
                    <AppLayout data={data}>
                        <CatchPage caught={error} />
                    </AppLayout>
                </AppProviders>
            );
        }
        return (
            <AppProviders>
                <CatchPage caught={error} />
            </AppProviders>
        );
    }

    let errorObj = new Error('Unknown error');
    if (error instanceof Error) {
        errorObj = error;
    }

    return (
        <AppProviders>
            <ServerErrorPage error={errorObj} />
        </AppProviders>
    );
};

const AppPage = () => {
    const appData = useAppData();

    return (
        <AppProviders>
            <AppLayout data={appData}>
                <Outlet />
            </AppLayout>
        </AppProviders>
    );
};

export default AppPage;
