import { MobileHeader } from './MobileHeader';
import { cva, type VariantProps } from 'class-variance-authority';
import { DesktopHeader } from './DesktopHeader';
import { DisplayMode } from '../../../types/enum/display-mode';

export const _headerVariants = cva(
    'w-full transition-all duration-50 z-fixed',
    {
        variants: {
            color: {
                default: 'bg-background text-primary',
                transparent: 'bg-transparent text-gray-50',
                legacy: 'bg-[#FAF9F7]',
                none: 'bg-transparent text-primary',
            },
            mode: {
                default: 'relative',
                fixed: 'fixed md:absolute',
                float: 'absolute',
            },
        },
        defaultVariants: {
            color: 'default',
            mode: 'default',
        },
    },
);

export interface HeaderCartItem {
    id: number;
    title: string;
    price: string;
    quantity: number;
    thumbnail: {
        desktop: string;
        mobile: string;
    };
    isSample: boolean;
}

export interface HeaderCartInfo {
    items: HeaderCartItem[];
    itemTotal: { ShortFormat: string; Amount: number };
    cartCount: number;
}

export interface HeaderBaseProps extends VariantProps<typeof _headerVariants> {
    userData: HeaderUserData;
    cartInfo: HeaderCartInfo;
    currentUrl: string;
    onLogout: () => void;
    onLoginClick: () => void;
    onUserCartClick: () => void;
    warningMessages?: string[];
    isLegacyBrokenRemMode?: boolean;
}

export interface HeaderProps extends HeaderBaseProps {
    // Specific props for the main Header component
    currentCountryCode: string;
    countries: HeaderCountryElement[];
    primaryDesktopLinks: HeaderNavLinks;
    primaryMobileLinks: HeaderNavLinks;
    secondaryMobileLinks: HeaderNavLinks;
    profileMobileLinks: HeaderNavLinks;
    displayMode: DisplayMode;
    additionalUserMenuItems?: HeaderNavLinks;
    onMobileSearchClick?: () => void;
}

export interface HeaderCountryElement {
    code: string;
    name: string;
    url: string;
}

export interface HeaderCompanyInbox {
    id: number;
    count: number;
    inboxType?: string;
}

export interface HeaderUserData {
    isAuthorized: boolean;
    avatarSrc?: string;
    firstName: string;
    lastName?: string;
    notificationsCount: number;
    professionals: HeaderUserDataProfessional[];
    inboxCount: number;
    designBoardCount: number;
    orderNotificationCount: number;
    websiteNotificationCount: number;
}

interface HeaderUserDataProfessional {
    id: number;
    title: string;
    logo: string;
    urlSegment: string;
    unreads: number;
}

export interface HeaderNavLinks {
    title?: string;
    links: Array<{
        title: string;
        to: string;
        badgeCount?: number;
        onClick?: () => void;
        external?: boolean;
        isNonRemixRoute?: boolean;
        prefix?: string;
    }>;
}

export const Header = (props: HeaderProps) => {
    const {
        // Base props
        color,
        userData,
        currentUrl,
        onLogout,
        onLoginClick,
        onUserCartClick,
        warningMessages,
        isLegacyBrokenRemMode,

        // Specific props
        mode,
        currentCountryCode,
        countries,
        primaryDesktopLinks,
        primaryMobileLinks,
        secondaryMobileLinks,
        profileMobileLinks,
        displayMode,
        additionalUserMenuItems,
        onMobileSearchClick,
        cartInfo,
    } = props;

    return displayMode === DisplayMode.Desktop ? (
        <DesktopHeader
            // Base props
            color={color}
            userData={userData}
            currentUrl={currentUrl}
            onLogout={onLogout}
            onLoginClick={onLoginClick}
            onUserCartClick={onUserCartClick}
            warningMessages={warningMessages}
            isLegacyBrokenRemMode={isLegacyBrokenRemMode}
            // Specific props
            primaryLinks={primaryDesktopLinks}
            currentCountryCode={currentCountryCode}
            countries={countries}
            additionalUserMenuItems={additionalUserMenuItems}
            cartInfo={cartInfo}
        />
    ) : (
        <MobileHeader
            // Base props
            color={color}
            userData={userData}
            currentUrl={currentUrl}
            onLogout={onLogout}
            onLoginClick={onLoginClick}
            onUserCartClick={onUserCartClick}
            warningMessages={warningMessages}
            isLegacyBrokenRemMode={isLegacyBrokenRemMode}
            // Specific props
            mode={mode}
            primaryLinks={primaryMobileLinks}
            secondaryLinks={secondaryMobileLinks}
            profileLinks={profileMobileLinks}
            onMobileSearchClick={onMobileSearchClick}
            cartInfo={cartInfo}
        />
    );
};
